/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useContext, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { ValidationContext } from '@/contexts/validation-context';
import { LocaleContext } from '@/contexts/locale-context';
import { ValidateAddressContext } from '@/contexts/validate-address-context';
import { MerchantContext } from '@/contexts/merchant-context';
import { ChangesContext } from '@/contexts/changes-context';
import validation from '@/utilities/validation';
import { joinErrorMessage } from '@/components/error-reporting-hoc/utils';

function ErrorReportingHOC(Component) {
  function ErrorReporting(props) {
    const { translate, loadedLocale } = useContext(LocaleContext);
    const validationContext = useContext(ValidationContext);
    const validateAddressContext = useContext(ValidateAddressContext);
    const merchantContext = useContext(MerchantContext);
    const changesContext = useContext(ChangesContext);
    const { fieldsToValidate } = merchantContext?.getFeature?.('addresses') || [];
    const {
      inputSelectorId, required, validationRules,
      defaultErrorMessage, defaultValue, formFieldId, onBlur,
      shouldHideValue, validateOnFirstRender,
    } = props;
    const [content, setContent] = useState(defaultValue ? String(defaultValue) : defaultValue);
    const [error, setError] = useState(defaultErrorMessage);

    const ref = useRef();
    const initialRender = useRef(true);

    const scrollView = () => {
      ref?.current?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'start',
      });
    };

    const isContentLengthValid = () => {
      let isValid = true;
      let isTooLong = false;
      let isTooShort = false;

      if (validationRules.minLength && content?.length < validationRules.minLength) {
        isValid = false;
        isTooShort = true;
      }

      if (validationRules.maxLength && content?.length > validationRules.maxLength) {
        isValid = false;
        isTooLong = true;
      }

      return {
        isValid,
        isTooLong,
        isTooShort,
      };
    };

    const contentPassesValidationRule = (rule) => {
      if (typeof validation.validationOptions[rule].rule === 'function') {
        if (validation.validationOptions[rule].rule(content, merchantContext, changesContext)) {
          return true;
        }
      } else if (!content?.length || content?.match(validation.validationOptions[rule].rule)) {
        return true;
      }

      return false;
    };

    /**
     * The validationRules.onSubmit is an array of regexs that say what a valid input is.
     * This functions return true if the content either doesn't have any validation rules,
     * or its value matches all of the rules.
     */
    const isContentValid = (rules) => {
      if (!rules?.length && isContentLengthValid().isValid) {
        return true;
      }

      let passedValidation = true;
      rules?.forEach((rule) => {
        if (!contentPassesValidationRule(rule)) {
          passedValidation = false;
        }
      });

      return passedValidation && isContentLengthValid().isValid;
    };

    const isContentRequiredButEmpty = () => {
      const trimmedContent = typeof content === 'string' ? content.trim() : content;
      return required && !shouldHideValue && !trimmedContent?.length;
    };

    const getErrorMessage = (rules, errorList) => {
      const errorMessage = [];

      if (isContentRequiredButEmpty()) {
        errorMessage.push(validationRules.requiredError);
      } else if (!isContentLengthValid().isValid) {
        errorMessage.push(isContentLengthValid().isTooLong ? validationRules.tooLongError : validationRules.tooShortError);
      } else if (!isContentValid(rules)) {
        rules.forEach((rule) => {
          /**
           * This condition checks whether the user's input doesn't match it's
           * RegEx rule found in validation.js. If true, the error message returned is from
           * the rule's 'error' key. If undefined, the error will be taken from
           * the default in details-form.
           */
          if (!contentPassesValidationRule(rule)) {
            const defaultErrorMsg = 0;
            errorMessage.push(translate(validation?.validationOptions[rule].error || errorList[defaultErrorMsg]));
          }
        });
      } else {
        errorMessage.push(defaultErrorMessage);
      }
      return joinErrorMessage(errorMessage);
    };

    const validateInput = (rules, errorValues) => {
      if (!validationContext?.attemptSubmit) {
        if (content?.length > 0) {
          const valid = isContentValid(validationRules.onSubmit);
          const requiredEmpty = isContentRequiredButEmpty();
          const errorMessage = getErrorMessage(
            rules, errorValues,
          );
          setError(errorMessage);
          validationContext?.updateValidity({
            id: inputSelectorId,
            valid,
            requiredEmpty,
            error: errorMessage,
          });
        } else {
          setError(defaultErrorMessage);
        }

        validationContext?.verifyFormError();
      }
    };

    const onInputBlur = () => {
      if (onBlur) {
        onBlur();
      }
      if (validationRules?.onBlur) {
        if (isContentRequiredButEmpty()) {
          setError(validationRules.requiredError);
          return;
        }
        validateInput(validationRules.onBlur, validationRules.onBlurErrors);
      }
    };

    useEffect(() => {
      if (formFieldId && validateAddressContext?.invalidAddressFields?.includes(formFieldId)) {
        if (formFieldId === 'auto-complete-input') {
          if (validateAddressContext?.addressCarrierError) {
            setError(validateAddressContext?.addressCarrierError);
          } else {
            setError(translate('wl.constants.addressNotValid'));
          }
        }
        // set an error border to inputs that require validation
        if (fieldsToValidate.includes(formFieldId)) {
          setError(' ');
        }
      }
    }, [validateAddressContext?.invalidAddressFields]);

    useEffect(() => {
      // Avoids triggering validation on component load, waits for user action instead (submitting/typing).
      // Use a prop if immediate validation is needed.
      if (initialRender.current && !validateOnFirstRender) return;
      if (validationContext?.attemptSubmit) {
        const valid = isContentValid(validationRules.onSubmit);
        const requiredEmpty = isContentRequiredButEmpty();
        const errorMessage = getErrorMessage(
          validationRules.onSubmit, validationRules.submitErrors,
        );
        setError(errorMessage);

        validationContext.reportValidity({
          id: inputSelectorId,
          valid,
          requiredEmpty,
          error: errorMessage,
        });
      }
    }, [validationContext?.attemptSubmit]);

    useEffect(() => {
      if (typeof defaultValue === 'string') {
        setContent(defaultValue);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (defaultErrorMessage) {
        setError(defaultErrorMessage);
      }
    }, [defaultErrorMessage]);

    useEffect(() => {
      validateInput(validationRules.onChange, validationRules.onChangeErrors);
    }, [content, loadedLocale]);

    useEffect(() => {
      if (validationContext?.formErrorMessage && !initialRender.current) {
        validationContext.attemptSubmission();
      }
    }, [loadedLocale]);

    useEffect(() => {
      initialRender.current = false;

      if (validationRules) {
        validationContext.incrementInputItemCount();
        return () => validationContext.decrementInputItemCount();
      }
      return () => {};
    }, []);

    useEffect(() => {
      if (error) {
        scrollView();
      }
    }, [error, validationContext?.attemptSubmit]);

    return (
      <Component
        {...props}
        content={content}
        setContent={setContent}
        error={error}
        ref={ref}
        onBlur={onInputBlur}
      />
    );
  }

  ErrorReporting.defaultProps = {
    validationRules: {
      onChange: null,
      onBlur: null,
      onSubmit: null,
      required: '',
      minLength: null,
      maxLength: null,
      onChangeErrors: null,
      onBlurErrors: null,
      submitErrors: null,
      tooLongError: null,
      tooShortError: null,
    },
    defaultErrorMessage: null,
    required: false,
    shouldHideValue: false,
    validateOnFirstRender: false,
    defaultValue: '',
    formFieldId: null,
    onBlur: null,
  };

  ErrorReporting.propTypes = {
    inputSelectorId: PropTypes.string.isRequired,
    validationRules: PropTypes.shape({
      onChange: PropTypes.arrayOf(PropTypes.string),
      onBlur: PropTypes.arrayOf(PropTypes.string),
      onSubmit: PropTypes.arrayOf(PropTypes.string),
      minLength: PropTypes.number,
      maxLength: PropTypes.number,
      requiredError: PropTypes.string,
      onChangeErrors: PropTypes.arrayOf(PropTypes.string),
      onBlurErrors: PropTypes.arrayOf(PropTypes.string),
      submitErrors: PropTypes.arrayOf(PropTypes.string),
      tooLongError: PropTypes.string,
      tooShortError: PropTypes.string,
    }),
    defaultErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    shouldHideValue: PropTypes.bool,
    validateOnFirstRender: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string, PropTypes.number, PropTypes.bool]),
    formFieldId: PropTypes.string,
    onBlur: PropTypes.func,
  };

  return ErrorReporting;
}

export default ErrorReportingHOC;
